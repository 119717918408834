import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Head from '../components/head'
import PageHeading from '../components/pageHeading'

export default () => {
    return (
        <Layout>
            <Head title="Articles" />
            <PageHeading text="Not Found" />

            <div>
                <p>This page could not be found. Maybe try heading back to the <Link to="/" className="underline">home page</Link>?</p>
            </div>
        </Layout>
    )
}
